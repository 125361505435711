.dropdownContainer {
  @apply fixed inset-0 top-16 z-50 flex h-screen w-screen select-none flex-col bg-white;
}

@screen sm {
  .dropdownContainer {
    @apply absolute inset-auto right-0 h-auto w-auto rounded-lg shadow;
    top: calc(100% + 16px);
    max-height: 70vh;
  }

  .dropdownContainer::before {
    @apply absolute -top-2 right-3.5;
    @apply border-b-8 border-r-8 border-l-8 border-solid;
    @apply border-transparent border-t-white border-b-white;
    filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.25));
    content: "";
  }
}
